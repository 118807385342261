// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { h, render } from "preact";
import merge from "lodash-es/merge";
import {
    WidgetConfig,
    BootstrapWidgetApi,
    EmbeddableComponent,
    InitializeIdentity,
    RecommendParams
} from "common/lib/interfaces";
import {
    Analytics,
    SessionStorageService,
    uscDataStorageEmpty
} from "common/lib/utils";
import { uniqBy } from "lodash-es";
import {
    MParticleEvents,
    SESSION_REFERRING_PAGE_KEY,
    SESSION_SUB_BRAND_NAME_KEY
} from "common/lib/constants/analytics";
import { Theme } from "common/lib/constants/config";

import App from "./app/App";
import {
    CONTEXTUAL_RECOMMENDS_WIDGET_DATA_ATTRIBUTE,
    EMBEDDABLE_PRODUCTS_WIDGET_DATA_ATTRIBUTE,
    SHOPPING_OUTLET
} from "./constants/components.registry";
import { IOpenWidgetParam } from "./app/config";
import "./version";
import {
    initUSCAnalytics,
    rescanUSCAnalytics,
    addErrorHandlers
} from "./utils/analytics";

import {
    loadPromisePolyfill,
    loadDefaultConfig,
    loadContextualRecommendationsWidget,
    loadAmazonPayScript,
    loadEmbeddableProductsWidget,
    loadCTThemeCSS
} from "./utils/loader";
import { isPageWhitelisted } from "./utils/dom";
import { setZIndex, validateConfig, validateZIndex } from "./utils/misc";
import { getRecommendations } from "./utils/personify";
import { checkInitializeIdentity, initializeIdentity } from "./utils/identity";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let appElement: any;

window.uscWidget = {
    async init(cfg: WidgetConfig) {
        try {
            loadPromisePolyfill();

            window.mParticle?.ready(async () => {
                await initUSCAnalytics(cfg.networkBrand);
            });

            addErrorHandlers();

            const defaultConfig = await loadDefaultConfig(
                cfg.networkBrand,
                cfg?.theme
            );

            const appHTML = document.createElement("div");
            appHTML.setAttribute("id", SHOPPING_OUTLET);
            appHTML.classList.add(`usc-outlet--theme`);

            document.body.appendChild(appHTML);

            this.config = validateConfig(merge(defaultConfig, cfg));

            await loadCTThemeCSS(this.config.theme, this.config.networkBrand);

            const zIndex = validateZIndex(this.config.zIndex);

            setZIndex(zIndex);

            if (
                this.config?.identity &&
                checkInitializeIdentity(this.config?.identity)
            ) {
                await initializeIdentity(
                    this.config.identity as Required<InitializeIdentity>
                );
            }

            if (this.config.amazonPay && this.config.amazonPay.enabled) {
                loadAmazonPayScript();
            }

            render(
                <App
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.config}
                    ref={el => {
                        appElement = el;
                    }}
                />,
                appHTML
            );
        } catch (error) {
            Analytics.logError(error);
        }
    },
    rescan() {
        try {
            if (appElement) {
                appElement.rescan();
            }

            rescanUSCAnalytics(this.config?.networkBrand);
        } catch (error) {
            Analytics.logError(error);
        }
    },
    async setTheme(theme: Theme, networkBrand?: string) {
        this.config = {
            ...this.config,
            theme,
            networkBrand: networkBrand || this.config.networkBrand
        };

        await loadCTThemeCSS(theme, this.config.networkBrand);
    },
    open(productParams: IOpenWidgetParam) {
        if (appElement) {
            appElement.openWidget(productParams);
        }
    },

    async showRecommendations(recommendParams?: RecommendParams) {
        if (!this.config?.ccwEnabled || !this.config?.personifyApiUrl) {
            return null;
        }

        let productsCount = 0;
        const limit = recommendParams?.limit ?? 12;
        const recommendationProducts = await getRecommendations(
            { ...(recommendParams ?? {}), limit },
            this.config.personifyApiUrl
        );
        productsCount = Math.min(recommendationProducts.length, limit);

        if (productsCount) {
            const areSponsoredCardsAllowed =
                !this.config.enablePageTags ||
                isPageWhitelisted(this.config.enablePageTags);

            loadContextualRecommendationsWidget(
                this.config,
                uniqBy(recommendationProducts, "productcode"),
                productsCount,
                areSponsoredCardsAllowed
            );
        }

        return {
            productsCount
        };
    },

    async showEmbeddableProductsWidget(recommendParams?: RecommendParams) {
        const rootElements = document.querySelectorAll<HTMLElement>(
            `[${EMBEDDABLE_PRODUCTS_WIDGET_DATA_ATTRIBUTE}]`
        );

        if (!rootElements.length || !this.config.networkBrand) {
            console.error(
                "Skipping embeddable widget loading due to conditions:",
                "rootElements:",
                !rootElements.length,
                "networkBrand:",
                !this.config.networkBrand
            );
            return;
        }

        try {
            await loadEmbeddableProductsWidget();
        } catch (err) {
            console.error("Can't load USC Embeddable Widget", err);
        }

        const ccwEnabled = this.config.epw?.embeddedCcwEnabled;
        const ccwPosition = (this.config.epw?.embeddedCcwPosition || 1) - 1;
        const shouldRender = ccwEnabled && ccwPosition >= 0;
        let embeddableComponents: EmbeddableComponent[] = [];
        if (shouldRender) {
            const ccwEmbeddableRootObject = {
                position: ccwPosition,
                type: "div",
                attributes: {
                    [CONTEXTUAL_RECOMMENDS_WIDGET_DATA_ATTRIBUTE]: "true",
                    class: "usc-embedded-ccw"
                }
            };
            embeddableComponents = [ccwEmbeddableRootObject];
        }

        const areSponsoredCardsAllowed =
            !this.config.enablePageTags ||
            isPageWhitelisted(this.config.enablePageTags);

        await window.uscEmbeddableWidget?.init({
            config: this.config,
            rootElements,
            embeddableComponents,
            areSponsoredCardsAllowed
        });

        if (shouldRender) {
            await this.showRecommendations(recommendParams);
        }
    },

    config: {} as WidgetConfig,
    uscEventAttributes: {
        domain: {
            getValue: () => window.location.host,
            events: [
                MParticleEvents.PRODUCT_VIEWED,
                MParticleEvents.PRODUCT_CLICKED,
                MParticleEvents.IAB_IMPRESSION,
                MParticleEvents.SPONSOR_CLICKED,
                MParticleEvents.SPONSOR_VIEWED,
                MParticleEvents.SPONSOR_IMPRESSION,
                MParticleEvents.SHOPPABLE_PAGE_LOAD,
                MParticleEvents.SHOPPABLE_CONTENT_LOAD,
                MParticleEvents.PRODUCT_LOADED,
                MParticleEvents.BACK_IN_STOCK,
                MParticleEvents.CART_VIEWED,
                MParticleEvents.CHECKOUT_METHOD_CLICK,
                MParticleEvents.CLICK_ACTION,
                MParticleEvents.VIDEO_START,
                MParticleEvents.VIEWED_SHOP_ALL_LIST,
                MParticleEvents.UNKNOWN,
                MParticleEvents.ADD_TO_CART,
                MParticleEvents.REMOVE_FROM_CART,
                MParticleEvents.CHECKOUT_OPTION,
                MParticleEvents.CLICK,
                MParticleEvents.VIEW_DETAIL,
                MParticleEvents.PURCHASE
            ]
        },
        referringPage: {
            getValue: () =>
                SessionStorageService.getItem(SESSION_REFERRING_PAGE_KEY) || "",
            events: [
                MParticleEvents.PRODUCT_VIEWED,
                MParticleEvents.PRODUCT_CLICKED,
                MParticleEvents.IAB_IMPRESSION,
                MParticleEvents.SPONSOR_CLICKED,
                MParticleEvents.SPONSOR_VIEWED,
                MParticleEvents.SPONSOR_IMPRESSION,
                MParticleEvents.SHOPPABLE_PAGE_LOAD,
                MParticleEvents.SHOPPABLE_CONTENT_LOAD,
                MParticleEvents.PRODUCT_LOADED,
                MParticleEvents.BACK_IN_STOCK,
                MParticleEvents.CART_VIEWED,
                MParticleEvents.CHECKOUT_METHOD_CLICK,
                MParticleEvents.CLICK_ACTION,
                MParticleEvents.VIDEO_START,
                MParticleEvents.VIEWED_SHOP_ALL_LIST,
                MParticleEvents.UNKNOWN,
                MParticleEvents.ADD_TO_CART,
                MParticleEvents.REMOVE_FROM_CART,
                MParticleEvents.CHECKOUT_OPTION,
                MParticleEvents.CLICK,
                MParticleEvents.VIEW_DETAIL,
                MParticleEvents.PURCHASE
            ]
        },
        subBrand: {
            getValue: () =>
                SessionStorageService.getItem(SESSION_SUB_BRAND_NAME_KEY) || "",
            events: [
                MParticleEvents.PRODUCT_VIEWED,
                MParticleEvents.PRODUCT_CLICKED,
                MParticleEvents.IAB_IMPRESSION,
                MParticleEvents.SPONSOR_CLICKED,
                MParticleEvents.SPONSOR_VIEWED,
                MParticleEvents.SPONSOR_IMPRESSION,
                MParticleEvents.SHOPPABLE_PAGE_LOAD,
                MParticleEvents.SHOPPABLE_CONTENT_LOAD,
                MParticleEvents.PRODUCT_LOADED,
                MParticleEvents.BACK_IN_STOCK,
                MParticleEvents.CART_VIEWED,
                MParticleEvents.CHECKOUT_METHOD_CLICK,
                MParticleEvents.CLICK_ACTION,
                MParticleEvents.VIDEO_START,
                MParticleEvents.VIEWED_SHOP_ALL_LIST,
                MParticleEvents.UNKNOWN,
                MParticleEvents.ADD_TO_CART,
                MParticleEvents.REMOVE_FROM_CART,
                MParticleEvents.CHECKOUT_OPTION,
                MParticleEvents.CLICK,
                MParticleEvents.VIEW_DETAIL,
                MParticleEvents.PURCHASE
            ]
        },
        pageSlug: {
            getValue: () => Analytics.getPageSlug(window.location.pathname),
            events: [
                MParticleEvents.PRODUCT_VIEWED,
                MParticleEvents.PRODUCT_CLICKED,
                MParticleEvents.SHOPPABLE_PAGE_LOAD,
                MParticleEvents.SHOPPABLE_CONTENT_LOAD,
                MParticleEvents.PRODUCT_LOADED,
                MParticleEvents.SPONSOR_IMPRESSION,
                MParticleEvents.SPONSOR_VIEWED,
                MParticleEvents.SPONSOR_CLICKED,
                MParticleEvents.IAB_IMPRESSION,
                MParticleEvents.CLICK,
                MParticleEvents.UNKNOWN,
                MParticleEvents.ADD_TO_CART,
                MParticleEvents.CHECKOUT_METHOD_CLICK,
                MParticleEvents.PURCHASE,
                MParticleEvents.VIEW_DETAIL,
                MParticleEvents.ADD_TO_CART
            ]
        },
        pageName: {
            getValue: () => document.title,
            events: [
                MParticleEvents.SPONSOR_IMPRESSION,
                MParticleEvents.SPONSOR_VIEWED,
                MParticleEvents.SPONSOR_CLICKED,
                MParticleEvents.IAB_IMPRESSION,
                MParticleEvents.SHOPPABLE_PAGE_LOAD,
                MParticleEvents.SHOPPABLE_CONTENT_LOAD,
                MParticleEvents.CHECKOUT_METHOD_CLICK
            ]
        },
        url: {
            getValue: () => window.location.toString(),
            events: [
                MParticleEvents.SHOPPABLE_PAGE_LOAD,
                MParticleEvents.SHOPPABLE_CONTENT_LOAD,
                MParticleEvents.SPONSOR_IMPRESSION,
                MParticleEvents.SPONSOR_VIEWED,
                MParticleEvents.SPONSOR_CLICKED,
                MParticleEvents.IAB_IMPRESSION,
                MParticleEvents.CHECKOUT_METHOD_CLICK
            ]
        }
    },
    uscDataStorage: uscDataStorageEmpty
} as BootstrapWidgetApi;
